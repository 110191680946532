<template>
  <div>
    <h3 class="title-g">账户明细</h3>
    <div class="databox-g sta">
      <div>
        商家余额：
        <span>{{ balanceBizTotal }}</span>元
      </div>
    </div>
    <div class="databox-g">
      <el-tabs v-model="activeName" @click="getAccountFn()">
        <el-tab-pane label="现金" name="900001"></el-tab-pane>
        <el-tab-pane label="应收款" name="900002"></el-tab-pane>
        <el-tab-pane label="收入" name="900003"></el-tab-pane>
        <el-tab-pane label="营销费用" name="900004"></el-tab-pane>
        <el-tab-pane label="备付金" name="900006"></el-tab-pane>
        <!-- <el-tab-pane label="券卡失效账户" name="900006"></el-tab-pane> -->
      </el-tabs>
      <el-tabs v-model="activeNameB" @click="changeType">
        <el-tab-pane label="明细" name="accountHistory">
          <accountinfo :custId="activeName" :custName="activeName" />
        </el-tab-pane>
        <el-tab-pane label="统计" name="accountSta">
          <p>十日数据</p>
          <hr />
          <table>
            <tr>
              <td>日期</td>
              <td v-for="(item, i) in dataDay" :key="i">{{ getDay(item.date) }}</td>
            </tr>
            <tr>
              <td>变动</td>
              <td v-for="(item, i) in dataDay" :key="i">{{ item.amount }}</td>
            </tr>
            <tr>
              <td>余额</td>
              <td v-for="(item, i) in dataDay" :key="i">{{ item.balance }}</td>
            </tr>
          </table>
          <br />
          <br />
          <p>月数据</p>
          <hr />
          <div class="list-g">
            <table>
              <tr>
                <td>日期</td>
                <td v-for="(item, i) in dataMonth" :key="i">{{ getDay(item.date) }}</td>
              </tr>
              <tr>
                <td>变动</td>
                <td v-for="(item, i) in dataMonth" :key="i">{{ item.amount }}</td>
              </tr>
              <tr>
                <td>余额</td>
                <td v-for="(item, i) in dataMonth" :key="i">{{ item.balance }}</td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import accountinfo from "../components/AccountInfo";
import { getAccount } from "@/api/api";
export default {
  components: {
    accountinfo
  },
  data() {
    return {
      activeName: "900001",
      activeNameB: "accountHistory",
      balanceStoreTotal: "",
      balanceBizTotal: "",
      balanceUserTotal: "",
      showDetail: false,
      dataDay: [],
      dataMonth: []
    };
  },

  mounted() {
    this.getAccountFn();
  },
  methods: {
    getAccountFn() {
      const that = this;
      getAccount({
        activeName: that.activeName,
        page: 1
      }).then(res => {
        if (res.result) {
          that.balanceBizTotal = res.data.balanceBizTotal;
          that.balanceStoreTotal = res.data.balanceStoreTotal;
          that.balanceUserTotal = res.data.balanceUserTotal;
          that.dataDay = res.data.dataDay;
          that.dataMonth = res.data.dataMonth;
        }
      });
    },
    getDay(item) {
      var eTime = new Date(item.replace(new RegExp("-", "gm"), "/"));
      return eTime.getMonth() + 1 + "-" + eTime.getDate();
    },
    changeType() {
      //request data
    }
  }
};
</script>

<style scoped>
.sta {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>